<template>
  <div class="destination-choice">
    <h2 class="destination-choice__title">
      Выбор пункта
    </h2>

    <div class="destination-choice__items">
      <div class="destination-choice__item">
        <h3>Регион: </h3>
        <city-search
          placeholder="Выберите регион"
          :search-params="regionParams"
          search-type="addresses"
          hide-double
          filterable
          @on-print="clear('region')"
          v-model="region"
        />
      </div>

      <div class="destination-choice__item">
        <h3>Район: </h3>
        <city-search
          placeholder="Выберите район"
          :search-params="areaParams"
          search-type="addresses"
          hide-double
          :disabled="!hasRegion"
          filterable
          @on-print="clear('area')"
          v-model="area"
        />
      </div>

      <div class="destination-choice__item">
        <h3>Город: </h3>
        <city-search
          placeholder="Выберите город"
          :search-params="cityParams"
          search-type="addresses"
          hide-double
          :disabled="!hasRegion"
          filterable
          @on-print="clear('city')"
          v-model="city"
        />
      </div>

      <div class="destination-choice__item">
        <h3>Населеный пункт (поселок, пгт и т.д.): </h3>
        <city-search
          placeholder="Выберите населеный пункт"
          :search-params="settlementParams"
          search-type="addresses"
          hide-double
          filterable
          :disabled="!hasRegion"
          v-model="settlement"
        />
      </div>
    </div>

    <div class="destination-choice__actions">
      <esmp-button
        view="outline"
        @click="$emit('close-modal')"
      >
        Закрыть
      </esmp-button>
      <esmp-button :disabled="!canSave" @click="emitInput">
        Сохранить
      </esmp-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

import { BUSINESS_TRIP_DESTINATION, DESTINATION_SEARCH_TYPE } from '@/constants/business-trip2';

import CitySearch from './CitySearch.vue';
import { getRegionCode, getAreaCode } from '../../helpers/getDestinationCode';

export default {
  name: 'DestinationModalFieldset',
  components: {
    CitySearch,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      region: null,
      area: null, // district
      city: null, // town
      settlement: null, // locality
    };
  },
  computed: {
    canSave() {
      if (
        this.region
        && ((this.region.abbrev === BUSINESS_TRIP_DESTINATION.cityAbbrev)
          || (this.area?.abbrev === BUSINESS_TRIP_DESTINATION.cityAbbrev))
      ) {
        return true;
      }

      return !isEmpty(this.city) || !isEmpty(this.settlement);
    },
    regionParams() {
      return {
        searchType: DESTINATION_SEARCH_TYPE.region,
      };
    },
    areaParams() {
      return {
        searchType: DESTINATION_SEARCH_TYPE.area,
        regionCode: this.region ? this.region.code : null,
      };
    },
    cityParams() {
      let districtCode = null;
      if (!isEmpty(this.area)) {
        districtCode = this.area.code;
      } else if (!isEmpty(this.region)) {
        districtCode = this.region.code;
      }

      return {
        searchType: DESTINATION_SEARCH_TYPE.city,
        regionCode: this.region ? this.region.code : null,
        districtCode,
      };
    },
    settlementParams() {
      let districtCode = null;
      if (!isEmpty(this.area)) {
        districtCode = this.area.code;
      } else if (!isEmpty(this.region)) {
        districtCode = this.region.code;
      }

      let cityCode = null;
      if (!isEmpty(this.city)) {
        cityCode = this.city.code;
      } else if (!isEmpty(this.area)) {
        cityCode = this.area.code;
      } else if (!isEmpty(this.region)) {
        cityCode = this.region.code;
      }

      return {
        searchType: DESTINATION_SEARCH_TYPE.settlement,
        regionCode: this.region ? this.region.code : null,
        districtCode,
        cityCode,
      };
    },
    hasRegion() {
      return !isEmpty(this.region);
    },
  },
  methods: {
    ...mapActions('businessTrip2', [
      'getArea',
    ]),
    async emitInput() {
      const now = new Date();
      const val = {
        id: this.value?.id || `${now.getTime()}`,
        region: !isEmpty(this.region) ? this.region : null,
        area: !isEmpty(this.area) ? this.area : null,
        city: !isEmpty(this.city) ? this.city : null,
        settlement: !isEmpty(this.settlement) ? this.settlement : null,
      };

      if (!val.area && (!isEmpty(this.city) || !isEmpty(this.settlement))) {
        const regionCode = getRegionCode(this.city);
        const areaCode = getAreaCode(this.city);

        if (areaCode !== regionCode) {
          val.area = await this.getArea({ regionCode, areaCode });
        }
      }

      this.$emit('input', val);
      this.$emit('close-modal');
    },
    setDestination() {
      if (this.value) {
        this.region = cloneDeep(this.value.region);
        this.area = cloneDeep(this.value.area);
        this.city = cloneDeep(this.value.city);
        this.settlement = cloneDeep(this.value.settlement);
      }
    },
    clear(destination) {
      // eslint-disable-next-line default-case
      switch (destination) {
      case 'region':
        this.area = null;
        this.city = null;
        this.settlement = null;
        break;
      case 'area':
        this.city = null;
        this.settlement = null;
        break;
      case 'city':
        this.settlement = null;
        break;
      }
    },
  },
  mounted() {
    this.setDestination();
  },
};
</script>

<style lang="scss" scoped>
.destination-choice {
  &__title {
    text-align: left;
  }
  &__item {
    position: relative;
    display: block;
    padding-bottom: 20px;
    &:first-child {
      z-index: 4;
    }
    &:nth-child(2) {
      z-index: 3;
    }
    &:nth-child(3) {
      z-index: 2;
    }
    &:nth-child(4) {
      z-index: 1;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
  }
}
</style>
