<template>
  <div
    class="city-search"
    :class="{ 'city-search--open': isOpen }"
    v-on-clickaway="closeList"
  >
    <div class="city-search__inner">
      <esmp-input
        :label="placeholder"
        :disabled="disabled"
        :loading="isLoad"
        error-message=""
        @focusin="isFocus = true"
        @focusout="isFocus = false"
        @input="waitCityPrint"
        v-model="query"
      />
    </div>

    <esmp-dropdown
      class="city-search__list"
      trigger="custom"
      :visible="!!cityList"
    >
      <template v-if="cityList">
        <div class="city-search__list-inner" v-if="filteredCityList.length">
          <div
            class="city-search__list-item"
            v-for="city in filteredCityList"
            :key="city.code"
            @click="selected(city)"
          >
            <span class="city-search__name">
              {{ city.name }} {{ city.abbrev }}
            </span>
            <span
              v-if="city.isDouble"
              class="city-search__district"
              v-html="city.districtName"
            />
          </div>
        </div>
        <div class="city-search__list-inner" v-else="">
          <div class="city-search__list-item">
            <span
              class="city-search__name"
            >По Вашему запросу ничего не найдено
            </span>
          </div>
        </div>
      </template>
    </esmp-dropdown>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

import { SEARCH_TIMEOUT_DELAY } from '@/constants/business-trip2';

export default {
  name: 'DestinationSearch',
  mixins: [clickaway],
  props: {
    placeholder: {
      type: String,
      default: 'Место назначения',
    },
    searchParams: {
      type: Object,
      default: () => ({}),
    },
    searchType: {
      type: String,
      validator(value) {
        return ['city', 'address'].includes(value);
      },
      default: 'city',
    },
    value: {
      type: Object,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    hideDouble: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      val: null,
      query: '',
      cityList: null,
      searchTimeoutId: null,
      isLoad: false,
      isFocus: false,
    };
  },
  computed: {
    isOpen() {
      return this.isFocus && this.cityList;
    },
    filteredCityList() {
      const value = [];

      const query = this.query.toLowerCase();

      const pushValue = (city, isDouble) => {
        // eslint-disable-next-line prefer-object-spread
        const valueItem = Object.assign({}, city);
        valueItem.isDouble = isDouble;
        value.push(valueItem);
      };

      this.cityList.forEach((city) => {
        // check name
        if (city.name.toLowerCase().includes(query)) {
          // check duplication
          const doubleIndex = value.findIndex(
            (item) => item.name === city.name && item.abbrev === city.abbrev,
          );
          if (doubleIndex !== -1) {
            if (this.hideDouble) return;
            value[doubleIndex].isDouble = true;

            pushValue(city, true);
          } else {
            pushValue(city, false);
          }
        }
      });

      return value;
    },
  },
  methods: {
    async search() {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);

      if (this.query.trim().length >= 3) {
        this.isLoad = true;

        const searchParams = {
          query: this.query,
          ...this.searchParams,
        };

        const method = this.searchType === 'city' ? 'citySearch' : 'getAddresses';

        await this.$API.businessTrip[method](searchParams)
          .then((response) => {
            this.cityList = response.data;
          })
          .finally(() => {
            this.isLoad = false;
          });
      }
    },
    waitCityPrint() {
      if (!this.isFocus) return;
      this.$emit('on-print');
      if (this.val) {
        this.val = null;
        this.$emit('input', null);
      }

      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
      this.searchTimeoutId = setTimeout(this.search, SEARCH_TIMEOUT_DELAY);
    },
    selected(city) {
      this.setCity(city);
      this.$emit('input', city);
    },
    setCity(city) {
      this.val = city;
      this.cityList = null;
      if (city && city.name) {
        this.query = city.name;
      } else if (!this.isFocus) {
        this.query = '';
      }
    },
    closeList() {
      this.cityList = null;
    },
  },
  created() {
    if (this.value) this.selected(this.value);
  },
  watch: {
    value(val) {
      this.selected(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.city-search {
  position: relative;
  z-index: 4;

  &--open {
    z-index: 5;
  }
  &__list {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    width: 100%;
    max-height: 180px;
    overflow: hidden;
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
    .select--open & {
      max-height: 300px;
    }
  }
  &__list-inner {
    width: 100%;
    height: auto;
    max-height: 180px;
    overflow: auto;
    .select--open & {
      max-height: 300px;
    }
  }
  &__list-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    line-height: normal;
    padding: 8px 16px;
    color: $color-grayscale-60;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    background-color: $color-white;
    &:hover {
      background-color: $color-grayscale-05;
    }
  }
  &__name {
    display: inline-block;
    margin-right: 6px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
  &__district {
    color: $secondary-color;
  }
}

</style>
